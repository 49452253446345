import FinancingCompanyPage from 'components/pages/financing-for-companies/FinancingCompanyPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Bezpieczne finansowanie | FINTEQ'
			keywords='porównanie metod finansowania, bezpieczny sposób finansowania, dowiedz się więcej, edukacja, wiedza, co to finteq, instrumenty finansowania, finansowanie dla firm'
			description='Dowiedz się jaki sposób finansowania dla firm jest najbezpieczniejszy. Poznaj najpopularniejsze metody, porównaj i wybierz mądrze.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<FinancingCompanyPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
